import React from 'react';

import H2 from '../H2';
import Wrapper from './Wrapper';
import ShareButton from './ShareButton';
import gplayImg from '../../gplay.png'

function GPlay({ title, url }) {
  const encodedURL = encodeURI(url);
  const text = encodeURI(title);
  return (
    <Wrapper>      
      <ShareButton href={`${encodedURL}`} target="_blank" aria-label="Download on Google Play">
        <img src={gplayImg}/>
      </ShareButton>
    </Wrapper>
  );
}

export default GPlay;