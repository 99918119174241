import React from 'react';

import FeaturedImage from '../FeaturedImage';
import H1 from '../H1';
import H2 from '../H2';
import P from '../P';
import Wrapper from './Wrapper';
import Link from './Link';
import Date from './Date';
import ContinueReading from './ContinueReading';
import GPlay from '../GPlay';

function Summary({date, subtitle, title, excerpt, slug, image, gplayUrl}) {
  return (
    <Wrapper>        
        <H1>{title}</H1>        
        {image &&      
            <FeaturedImage sizes={image.childImageSharp.sizes}/>          
        }   
        <P>{excerpt}</P>
        <H2>{subtitle}</H2>
        <GPlay url={gplayUrl}/>
        {/* <ContinueReading to={slug}>Continue Reading &rarr;</ContinueReading> */}
    </Wrapper>
  );
}

export default Summary;